<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-overlay :show="show" rounded="sm">
      <b-card no-body class="mb-0">
        <div class="m-2">
          <b-row>
            <b-col>
              <label>{{ $t('Show') }}</label>
              <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
                :clearable="false" class="per-page-selector v-select-custom" @input="pageSize" />
              <label for="">{{ $t('entries') }}</label>
              <span class="ml-1 text-muted">{{ currentPage * perPage - (perPage - 1) }} -
                {{
                  queriedItems - currentPage * perPage > 0
                  ? currentPage * perPage
                  : queriedItems
                }}
                {{ $t("of") }} {{ queriedItems }}</span>
            </b-col>

            <b-col cols="12" md="6" class="b-col-custom">
              <div class="d-flex align-items-center justify-content-end">
                <datepicker class="d-inline-block mr-1" :placeholder="$t('Start Date')" v-model="Startdate"></datepicker>

                <datepicker class="d-inline-block mr-1" v-if="Startdate" :placeholder="$t('End Date')" v-model="Enddate"
                  @input="selectDate">
                </datepicker>

                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="d-flex"
                  @click="exportExcel()">
                  <feather-icon icon="SaveIcon" class="mr-1" />
                  {{ this.$t('Export') }}
                </b-button>

              </div>
            </b-col>
          </b-row>
        </div>
        <b-table ref="refUserListTable" striped responsive class="position-relative" :items="respData"
          :fields="tableColumns" primary-key="_id" show-empty :empty-text="$t('No matching records found')"
          style="height: 60vh;">
          <template #cell(name)="data">
            <p class="align-text-bottom line-height-1">
              {{ data.item.firstName }} {{ data.item.lastName }}</p>
          </template>
          <template #cell(tel)="data">
            <p class="align-text-bottom line-height-1">{{ data.item.tel == null ? "-" : data.item.tel }}</p>
          </template>
          <template #cell(email)="data">
            <p class="align-text-bottom line-height-1">{{ data.item.userId.email == null ? "-" : data.item.userId.email }}
            </p>
          </template>
          <template #cell(date)="data">
            <p class="align-text-bottom line-height-1">{{ data.item.userId.created | formatDateTime }}</p>
          </template>
          <template #cell(version)="data">
            <p class="align-text-bottom line-height-1">{{ data.item.userId.version == null ? "-" :
              data.item.userId.version }}</p>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">{{ currentPage * perPage - (perPage - 1) }} -
                {{
                  queriedItems - currentPage * perPage > 0
                  ? currentPage * perPage
                  : queriedItems
                }}
                {{ $t("of") }} {{ queriedItems }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

              <b-pagination v-model="currentPage" :total-rows="queriedItems" :per-page="perPage" first-number last-number
                class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @input="nextPage">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol,
  BImg,
  BAvatar,
  BFormInput, BButton,
  BTable, BPagination, BDropdown, BOverlay,
  BFormCheckbox,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/hero/reportService'
import debounce from 'debounce'
import XLSX from 'xlsx' // import xlsx
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'

const STORE_MODULE_NAME = 'reportService'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    Datepicker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      currentPage: 1,
      searchQuery: '',
      perPage: localStorage.getItem('itemsPerPage') ? localStorage.getItem('itemsPerPage') : 50,
      isSidebarActive: false,
      sidebarData: {},
      show: false,
      perPageOptions,
      Startdate: null,
      Enddate: null,
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'reservation_code', label: this.$t('Reservation code'), sortable: false },
        { key: 'name_surname', label: this.$t('Name - Surname'), sortable: false },
        { key: 'counselor', label: this.$t('Counselor'), sortable: false },
        { key: 'booking_date', label: this.$t('Booking date'), sortable: false },
        { key: 'service_type', label: this.$t('Service Type'), sortable: false },
      ]
    },
    respData() {
      return store.state[STORE_MODULE_NAME].respData != null ? store.state[STORE_MODULE_NAME].respData.data : []
    },
    queriedItems() {
      return store.state[STORE_MODULE_NAME].respData != null ? store.state[STORE_MODULE_NAME].respData.max : 0
    },
    // eslint-disable-next-line vue/return-in-computed-property
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    const obj = {
      currentPage: this.currentPage,
      pageSize: this.perPage,
      searchQuery: this.searchQuery,
    }
    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME}/get`, obj)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        this.show = false
        // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    this.typing = debounce(this.typing, 500)
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    exportExcel() {
      const today = moment(new Date()).format('DD-MM-YYYY')
      let nameFile = `report_service_${today}.xlsx`
      if (this.Startdate && this.Enddate) {
        nameFile = `report_service__date_${moment(this.Startdate).format('DD_MM_YYYY')}-${moment(this.Enddate).format('DD_MM_YYYY')}.xlsx`
      }

      const dataWS = XLSX.utils.json_to_sheet(this.respData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, dataWS)
      XLSX.writeFile(wb, nameFile)
      // this.$vs.loading.close()
    },
    sidebarAddNew() {
      this.sidebarData = {}
      this.isSidebarActive = true
    },
    sidebarEdit(item) {
      this.sidebarData = item
      this.isSidebarActive = true
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
    typing(value) {
      console.log(value)
      this.searchQuery = value
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    selectDate() {
      const obj = {
        start: this.Startdate,
        end: this.Enddate,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    pageSize(size) {
      localStorage.setItem('itemsPerPage', size)
      this.perPage = size
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    nextPage(page) {
      this.currentPage = page
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        .then(result => {
          this.show = false
          console.log('fetch Success : ', result)
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.b-col-custom {
  margin-top: 0.5rem;
}
</style>
